<template>

    <el-card class="mb-5" shadow="never">
        <el-row :gutter="10">
            <el-col :span="12" class="text-left">
                <el-button type="primary" size="small" icon="upload" :disabled="!currentYearCutting || !currentCourseId || !activeEvaluations.length" @click="handleDownload()">
                    {{ $t('exportNotes') }}
                </el-button>
                <el-button type="warning" size="small" icon="download" :disabled="!currentYearCutting || !currentCourseId || !activeEvaluations.length" @click="uploadDialog = true">
                    {{ $t('importNotes') }}
                </el-button>
                <el-button type="success" size="small" icon="document" :disabled="!currentYearCutting || !currentCourseId || !activeEvaluations.length" @click="currentCourseToPDF()">
                    {{ $t('downloadInPDF') }}
                </el-button>
            </el-col>
            <el-col :span="5" class="text-right">
                <el-select
                    size="small"
                    filterable
                    v-model="currentYearCutting"
                    :no-data-text="$t('noData')"
                    :no-match-text="$t('noMatchingData')"
                    :placeholder="isYearCuttingTerm ? $t('selectTrimester') : $t('selectSemester')"
                    @change="loadList()"
                    style="width: 100%">
                    <el-option
                        v-for="item in yearCuttings"
                        :key="item.id"
                        :label="getYearCuttingName(item.id)"
                        :value="item.id">
                    </el-option>
                </el-select>
            </el-col>
            <el-col :span="7" class="text-right">
                <el-select
                    size="small"
                    filterable
                    v-model="currentCourseId"
                    :no-data-text="$t('noData')"
                    :no-match-text="$t('noMatchingData')"
                    :placeholder="$t('selectCourse')"
                    @change="loadList()"
                    style="width: 100%">
                    <el-option
                        v-for="item in classCourses"
                        :key="item.course"
                        :label="getCourseName(item.course)"
                        :value="item.course">
                    </el-option>
                </el-select>
            </el-col>
        </el-row>
    </el-card>

    <el-card shadow="hover" v-if="currentClass" class="list-container-card">

        <div v-loading="isLoading || isCurrentDataLoading">

            <el-card shadow="never" class="table-header">
                <el-row :gutter="10">
                    <el-col :span="14" class="text-left"></el-col>
                    <el-col :span="5" class="text-right">

                        <template v-if="editMode">
                            <el-row :gutter="10">
                                <el-col :span="12">
                                    <el-button @click="cancelItemsEdition()" size="small" icon="close">{{ $t('cancel') }}</el-button>
                                </el-col>
                                <el-col :span="12">
                                    <el-button type="primary" @click="submitItemsEdition()" icon="check" size="small">{{ $t('validate') }}</el-button>
                                </el-col>
                            </el-row>
                        </template>

                        <el-button
                            size="small"
                            @click="editItems()"
                            :disabled="!currentYearCutting || !currentCourseId || !activeEvaluations.length"
                            icon="edit"
                            v-else>
                            {{ $t('editNotes') }}
                        </el-button>

                    </el-col>
                    <el-col :span="5" class="text-right">
                        <el-input v-model="search" :disabled="editMode" size="small" :placeholder="$t('search')" />
                    </el-col>
                </el-row>
            </el-card>

            <el-table
                stripe
                border
                max-height="500"
                :data="pagedTableData"
                :default-sort="{prop: 'lastname', order: 'ascending'}"
                :empty-text="$t('noData')" style="width: 100%">

                <el-table-column type="index" :index="indexMethod" width="50" align="center" fixed> </el-table-column>
                <el-table-column :label="$t('photo')" prop="photo" width="90" align="center" header-align="center" fixed>
                    <template #default="scope">
                        <el-avatar :src="scope.row.photo" v-if="scope.row.photo"></el-avatar>
                        <el-avatar icon="avatar" v-else></el-avatar>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('lastname')" prop="lastname" show-overflow-tooltip min-width="150" fixed> </el-table-column>
                <el-table-column :label="$t('firstnames')" prop="firstname" show-overflow-tooltip min-width="160" fixed> </el-table-column>

                <template v-if="currentYearCutting && currentCourseId">

                    <el-table-column align="center" :label="getEvaluationTypeName(item.type)" :prop="'note_' + item.id"
                        v-for="item in activeEvaluations"
                        :key="item.id" min-width="160">

                        <template #header>
                            <el-tooltip :content="getEvaluationTypeName(item.type)" placement="top">
                                <span class="table-header-overflow">{{ getEvaluationTypeName(item.type) }}</span>
                            </el-tooltip>
                        </template>

                        <template #default="scope">

                            <el-row :gutter="10" v-if="editMode">
                                <el-col :span="24">
                                    <el-input-number
                                        style="width: 100%"
                                        v-model="editItemsData[scope.$index]['note_' + item.id]"
                                        controls-position="right"
                                        :label="$t('note')"
                                        :step="0.25"
                                        :max="20"
                                        :min="0">
                                    </el-input-number>
                                </el-col>
                            </el-row>

                            <template v-else>

                                <el-tag
                                    :type="scope.row['note_' + item.id] < 10 ? 'danger' : ''"
                                    v-if="typeof scope.row['note_' + item.id] == VarTypes.NUMBER">
                                    {{ scope.row['note_' + item.id] }}
                                </el-tag>

                            </template>

                        </template>

                    </el-table-column>

                    <el-table-column :label="$t('attitude')" prop="attitude" min-width="180">
                        <template #default="scope">

                            <el-row :gutter="10" v-if="editMode">
                                <el-col :span="24">
                                    <el-input
                                        type="textarea"
                                        style="width: 100%"
                                        v-model="editItemsData[scope.$index].attitude"
                                        :placeholder="$t('attitude')">
                                    </el-input>
                                </el-col>
                            </el-row>

                            <template v-else>

                                {{ scope.row.attitude }}

                            </template>

                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('progression')" prop="progression" min-width="180">
                        <template #default="scope">

                            <el-row :gutter="10" v-if="editMode">
                                <el-col :span="24">
                                    <el-input
                                        type="textarea"
                                        style="width: 100%"
                                        v-model="editItemsData[scope.$index].progression"
                                        :placeholder="$t('progression')">
                                    </el-input>
                                </el-col>
                            </el-row>

                            <template v-else>

                                {{ scope.row.progression }}

                            </template>

                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('advices')" prop="advice" min-width="180">
                        <template #default="scope">

                            <el-row :gutter="10" v-if="editMode">
                                <el-col :span="24">
                                    <el-input
                                        type="textarea"
                                        style="width: 100%"
                                        v-model="editItemsData[scope.$index].advice"
                                        :placeholder="$t('advices')">
                                    </el-input>
                                </el-col>
                            </el-row>

                            <template v-else>

                                {{ scope.row.advice }}

                            </template>

                        </template>
                    </el-table-column>

                </template>

            </el-table>

            <div style="text-align: center; margin-top: 20px">

                <el-pagination
                    background
                    layout="prev, pager, next"
                    @current-change="setPage"
                    :hide-on-single-page="true"
                    :page-size="pageSize"
                    :total="total">
                </el-pagination>

            </div>

        </div>

    </el-card>

    <el-dialog
        width="40%"
        destroy-on-close
        v-model="uploadDialog"
        :title="$t('importNotes')">

        <upload-excel :on-success="handleImport"/>

    </el-dialog>

    <el-dialog
        width="35%"
        :title="$t('downloadInPDF')"
        v-model="downloadOptionsDialog">

        <div class="text-center">

            <el-radio-group v-model="downloadOption">
                <el-radio :label="1" border>{{ $t('currentCourse') }}</el-radio>
                <el-radio :label="2" border>{{ $t('allCourses')}}</el-radio>
            </el-radio-group>

        </div>

        <template #footer>
            <span class="dialog-footer">
                <el-button @click="downloadOptionsDialog = false">{{ $t('cancel') }}</el-button>
                <el-button type="primary" @click="downloadOption === 1 ? currentCourseToPDF() : allCoursesToPDF()">{{ $t('confirm') }}</el-button>
            </span>
        </template>

    </el-dialog>

</template>

<script>
import { mapState, mapActions } from 'vuex'

import UploadExcel from '@/components/widgets/UploadExcel'

import accessManager from '@/mixins/access-manager'
import currentData from '@/mixins/current-data'

import { VarTypes } from '@/utils/enums'

import jsPDF from 'jspdf'
import 'jspdf-autotable'

import fr from 'element-plus/lib/locale/lang/fr'

export default {
    name: 'ClassList',
    mixins: [accessManager, currentData],
    components: {
        UploadExcel
    },
    props: {
        currentClass: {
            default: 0
        }
    },
    created() {

        this.fetchData()

        this.currentYearCutting = this.defaultYearCutting

    },
    data() {
        return {
            VarTypes,
            primaryColor: process.env.VUE_APP_PRIMARY_COLOR,
            editItemsData: null,
            editMode: false,
            downloadOption: 1,
            isLoading: false,
            currentCourseId: null,
            currentYearCutting: null,
            downloadOptionsDialog: false,
            uploadDialog: false,
            evaluationNotes: [],
            evaluationTexts: [],
            classCourses: [],
            evaluations: [],
            tableData: [],
            pageSize: 50,
            locale: fr,
            search: '',
            total: 0,
            page: 1
        }
    },
    watch: {
        isCurrentDataLoading: {
            handler(val, oldVal) { this.loadList() }
        }
    },
    computed: {
        ...mapState({
            userData: state => state.session.userData
        }),
        pagedTableData() {

            this.total = this.searching.length

            return this.searching.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)

        },
        searching() {

            if (!this.search) {
                this.total = this.tableData.length
                return this.tableData
            }

            this.page = 1

            return this.tableData.filter(
                data => (data.lastname.toLowerCase().includes(this.search.toLowerCase())
                        || data.firstname.toLowerCase().includes(this.search.toLowerCase()))
            )

        },
        activeEvaluations(){

            return this.$_.sortBy( this.evaluations.filter(e => parseInt(e.display) && e.yearcutting == this.currentYearCutting), 'type' ).reverse()

        },
        classStudents(){

            if (this.currentClass) return this.$_.sortBy( this.students.filter(s => s.class == this.currentClass.id), 'lastname' )
            else return []

        },
        currentCourseTeacher(){

            if (this.classCourses && this.currentCourseId){

                let classCourse = this.classCourses.find(c => c.course == this.currentCourseId)

                return classCourse ? classCourse.teacher : ''

            }

            return ''

        }
    },
    methods: {
        ...mapActions({
            fetchRepartitionDB: 'repartition/fetchRepartitionDB',
            fetchEvaluationsDB: 'evaluations/fetchEvaluationsDB',
            fetchEvaluationNotesDB: 'evaluationnotes/fetchEvaluationNotesDB',
            fetchEvaluationTextsDB: 'evaluationtexts/fetchEvaluationTextsDB'
        }),
        setPage (val) {

            this.page = val

        },
        indexMethod(index) {

            return index + ((this.page - 1) * this.pageSize) + 1

        },
        async fetchData(){

            this.isLoading = true

            let resp1 = await this.fetchRepartitionDB({
                action: 'getClassCourses',
                params: {
                    class: this.currentClass.id,
                    schoolyear: this.currentSchoolyear
                }
            })

            if (resp1.success && resp1.data) this.classCourses = resp1.data

            if (this.currentClass){

                let resp2 = await this.fetchEvaluationsDB({
                    action: 'getClassEvaluations',
                    params: {
                        class: this.currentClass.id,
                        schoolyear: this.currentSchoolyear
                    }
                })

                if (resp2.success && resp2.data) this.evaluations = resp2.data

            }

            this.isLoading = false

        },
        async loadList(){

            this.editMode = false

            this.updateDefaultYearCutting(this.currentYearCutting)

            if (this.$route.params.class && this.currentCourseId){

                let resp = await this.fetchEvaluationNotesDB({
                    action: 'getClassEvaluationNotes',
                    params: {
                        class: this.$route.params.class,
                        schoolyear: this.currentSchoolyear
                    }
                })

                if (resp.success && resp.data) this.evaluationNotes = resp.data

                let resp_ = await this.fetchEvaluationTextsDB({
                    action: 'getClassEvaluationTexts',
                    params: {
                        class: this.$route.params.class,
                        schoolyear: this.currentSchoolyear
                    }
                })

                if (resp_.success && resp_.data) this.evaluationTexts = resp_.data

            }

            this.tableData = []

            this.classStudents.forEach(student => {

                let data = {
                    id: student.id,
                    lastname: student.lastname,
                    firstname: student.firstname
                }

                if (this.currentYearCutting && this.currentCourseTeacher){

                    this.evaluationNotes.forEach(ev => {

                        if (ev.course == this.currentCourseId && ev.yearcutting == this.currentYearCutting && ev.teacher == this.currentCourseTeacher && ev.student == student.id){

                            data['note_' + ev.evaluation] = parseFloat(ev.note)
                            data['note_' + ev.evaluation + '_id'] = ev.id

                        }

                    })

                    this.evaluationTexts.forEach(ev => {

                        if (ev.course == this.currentCourseId && ev.yearcutting == this.currentYearCutting && ev.teacher == this.currentCourseTeacher && ev.student == student.id){

                            data['attitude'] = ev.attitude
                            data['progression'] = ev.progression
                            data['advice'] = ev.advice

                        }

                    })

                }

                this.tableData.push(data)

            })

        },
        editItems(){

            this.editItemsData = this.$_.cloneDeep(this.tableData)

            this.search = ''

            this.editMode = true

        },
        cancelItemsEdition(){

            this.editItemsData = null

            this.editMode = false

        },
        async submitItemsEdition(){

            for (let y = 0; y < this.editItemsData.length; y++) {

                if (!this.$_.isEqual(this.editItemsData[y], this.tableData[y])) {

                    for (let i = 0; i < this.activeEvaluations.length; i++) {

                        if (typeof this.editItemsData[y]['note_' + this.activeEvaluations[i].id] === VarTypes.UNDEFINED) {

                            await this.fetchEvaluationNotesDB({
                                action: 'deleteEvaluationNote',
                                params: {
                                    id: this.editItemsData[y]['note_' + this.activeEvaluations[i].id + '_id'] || 0
                                }
                            })

                        } else {

                            await this.fetchEvaluationNotesDB({
                                action: 'updateEvaluationNote',
                                params: {
                                    course: this.currentCourseId,
                                    class: this.$route.params.class,
                                    evaluation: this.activeEvaluations[i].id,
                                    yearcutting: this.currentYearCutting,
                                    teacher: this.currentCourseTeacher,
                                    schoolyear: this.currentSchoolyear,
                                    student: this.editItemsData[y].id,
                                    note: this.editItemsData[y]['note_' + this.activeEvaluations[i].id]
                                }
                            })

                        }

                    }

                    await this.fetchEvaluationTextsDB({
                        action: 'updateEvaluationText',
                        params: {
                            course: this.currentCourseId,
                            class: this.$route.params.class,
                            yearcutting: this.currentYearCutting,
                            teacher: this.currentCourseTeacher,
                            schoolyear: this.currentSchoolyear,
                            student: this.editItemsData[y].id,
                            attitude: this.editItemsData[y].attitude || '',
                            progression: this.editItemsData[y].progression || '',
                            advice: this.editItemsData[y].advice || ''
                        }
                    })

                }

            }


            this.loadList()

            this.editMode = false

        },
        handleDownload() {

            let mes = this.$message({ message: this.$t('pleaseWait...'), duration: 0 })

            import('@/utils/export2excel').then(excel => {

                var tHeader = ['ID', this.$t('lastname'), this.$t('firstnames')],
                    filterVal = ['id', 'lastname', 'firstname']

                this.activeEvaluations.forEach(evaluation => {

                    tHeader.push(this.getEvaluationTypeName(evaluation.type))
                    filterVal.push('note_' + evaluation.id)

                })

                tHeader.push(...[this.$t('attitude'), this.$t('progression'), this.$t('advices')])
                filterVal.push(...['attitude', 'progression', 'advice'])

                const list = this.$_.sortBy( this.tableData, 'lastname' )
                const data = this.formatJson(filterVal, list)

                try {

                    excel.export_json_to_excel({
                        header: tHeader,
                        data,
                        filename: this.currentClass ? (this.getClassName(this.currentClass.id) + (' - ' + this.getCourseName(this.currentCourseId)) + (' - ' + this.getYearCuttingName(this.currentYearCutting)) ) : '',
                        autoWidth: true,
                        bookType: 'xlsx'
                    })

                    mes.close()

                } catch (error) {

                    mes.close()

                    this.$message.error(this.$t('anErrorOccured'))

                }

            })

        },
        async handleImport({ results, header }) {

            let labels = [], ids = []

            this.activeEvaluations.forEach(evaluation => {

                labels.push(this.getEvaluationTypeName(evaluation.type))
                ids.push(evaluation.id)

            })

            for (let y = 0; y < results.length; y++) {

                let student = this.tableData.find(d => d.id == results[y].ID)

                if (student) {

                    for (let i = 0; i < labels.length; i++) {

                        if (typeof results[y][labels[i]] != VarTypes.UNDEFINED){

                            await this.fetchEvaluationNotesDB({
                                action: 'updateEvaluationNote',
                                params: {
                                    class: this.$route.params.class,
                                    course: this.currentCourseId,
                                    evaluation: ids[i],
                                    yearcutting: this.currentYearCutting,
                                    teacher: this.currentCourseTeacher,
                                    schoolyear: this.currentSchoolyear,
                                    student: student.id,
                                    note: Number.isNaN(parseFloat(results[y][labels[i]])) ? '' : parseFloat(results[y][labels[i]])
                                }
                            })

                        }

                    }

                    await this.fetchEvaluationTextsDB({
                        action: 'updateEvaluationText',
                        params: {
                            course: this.currentCourseId,
                            class: this.$route.params.class,
                            yearcutting: this.currentYearCutting,
                            teacher: this.currentCourseTeacher,
                            schoolyear: this.currentSchoolyear,
                            student: student.id,
                            attitude: results[y][this.$t('attitude')] || '',
                            progression: results[y][this.$t('progression')] || '',
                            advice: results[y][this.$t('advices')] || ''
                        }
                    })

                }

            }

            this.loadList()

            this.uploadDialog = false

        },
        currentCourseToPDF() {

            this.downloadOptionsDialog = false

            var tHeader = ['N°', this.$t('lastname'), this.$t('firstnames')],
                filterVal = ['num', 'lastname', 'firstname'],
                finalHeader = []

            var columnStyles = {
                num: { halign: 'center' },
                lastname: { halign: 'left' },
                firstname: { halign: 'left' },
                attitude: { halign: 'left' },
                progression: { halign: 'left' },
                advice: { halign: 'left' }
            }

            this.activeEvaluations.forEach(evaluation => {

                tHeader.push(this.getEvaluationTypeName(evaluation.type))
                filterVal.push('note_' + evaluation.id)

                columnStyles['note_' + evaluation.id] = { halign: 'center', valign: 'middle' }

            })

            tHeader.push(...[this.$t('attitude'), this.$t('progression'), this.$t('advices')])
            filterVal.push(...['attitude', 'progression', 'advice'])

            for (let i = 0; i < filterVal.length; i++) {

                finalHeader.push({
                    header: tHeader[i],
                    dataKey: filterVal[i]
                })

            }

            var tempList = this.$_.sortBy( this.tableData, 'lastname' )
            for (let i = 0; i < tempList.length; i++) {

                tempList[i].num = i + 1

            }

            const data = this.formatJson(filterVal, tempList)

            const doc = new jsPDF('landscape')

            var finalY = doc.lastAutoTable.finalY || 10

            doc.text(this.currentClass ? this.getClassName(this.currentClass.id) : '', 14, finalY + 15)
            doc.setFontSize(11)
            doc.text(this.getYearCuttingName(this.currentYearCutting), 14, finalY + 25)
            doc.setFontSize(9)
            doc.text(this.$t('course') + ' - ' +this.getCourseName(this.currentCourseId), 14, finalY + 30)

            doc.autoTable({
                body: data,
                theme: 'grid',
                columns: finalHeader,
                columnStyles: columnStyles,
                bodyStyles: { cellPadding: {top: 4, right: 2, bottom: 4, left: 2} },
                headStyles: { fillColor: [33, 150, 243] },
                startY: finalY + 40
            })

            doc.save( (this.currentClass ? (this.getClassName(this.currentClass.id) + (' - ' + this.getCourseName(this.currentCourseId)) + (' - ' + this.getYearCuttingName(this.currentYearCutting)) ) : 'list') + '.pdf' )

        },
        allCoursesToPDF(){

            this.downloadOptionsDialog = false

            var tHeader = ['N°', this.$t('lastname'), this.$t('firstnames')],
                filterVal = ['num', 'lastname', 'firstname'],
                finalHeader = [],
                teachers = {},
                students = {},
                courses = {}

            var columnStyles = {
                num: { halign: 'center' },
                lastname: { halign: 'left', fontSize: 8, minCellWidth: 30 },
                firstname: { halign: 'left', fontSize: 8, minCellWidth: 35 }
            }

            this.classCourses.forEach(classCourse => {

                tHeader.push(this.getCourseCode(classCourse.course))
                filterVal.push('course_' + classCourse.course)

                teachers['course_' + classCourse.course] = classCourse.teacher
                courses['course_' + classCourse.course] = classCourse.course

                columnStyles['course_' + classCourse.course] = { halign: 'center', valign: 'middle', minCellWidth: 15, minCellHeight: 18 }

            })

            for (let i = 0; i < filterVal.length; i++) {

                finalHeader.push({
                    header: tHeader[i],
                    dataKey: filterVal[i]
                })

            }

            var tempList = this.$_.sortBy( this.tableData, 'lastname' )
            for (let i = 0; i < tempList.length; i++) {

                tempList[i].num = i + 1
                students[i + 1] = tempList[i].id

            }

            const data = this.formatJson(filterVal, tempList)

            const doc = new jsPDF('landscape')

            var finalY = doc.lastAutoTable.finalY || 10

            doc.text(this.currentClass ? this.getClassName(this.currentClass.id) : '', 14, finalY + 15)
            doc.setFontSize(11)
            doc.text(this.getYearCuttingName(this.currentYearCutting), 14, finalY + 25)

            var self = this

            doc.autoTable({
                body: data,
                theme: 'grid',
                columns: finalHeader,
                columnStyles: columnStyles,
                bodyStyles: { cellPadding: {top: 4, right: 2, bottom: 4, left: 2}, minCellWidth: 10 },
                headStyles: { fillColor: [33, 150, 243] },
                startY: finalY + 30,
                didDrawCell: function (data) {

                    if (data.column.index > 2 && data.row.section === 'body') {

                        let labels = [], notes = [], lastI = 0

                        for (let i = 0; i < self.activeEvaluations.length; i++) {

                            let evaluation = self.activeEvaluations[i]

                            let evNotes = self.evaluationNotes.filter(ev => (
                                ev.student == students[data.row.cells.num.raw] &&
                                ev.teacher == teachers[data.column.dataKey] &&
                                ev.course == courses[data.column.dataKey] &&
                                ev.yearcutting == self.currentYearCutting &&
                                ev.evaluation == evaluation.id &&
                                ev.class == self.currentClass.id
                            ))

                            let label = self.getEvaluationTypeCode(evaluation.type).charAt(0).toUpperCase() +
                                        (labels.filter(h => h.includes(self.getEvaluationTypeCode(evaluation.type).charAt(0).toUpperCase())).length + 1)

                            labels.push(label)

                            if (evNotes.length) {
                                doc.text(label + ': ', data.cell.x + 1, data.cell.y + (5 * (i + 1)))
                                doc.text(evNotes[0].note, data.cell.x + 7, data.cell.y + (5 * (i + 1)))

                                notes.push(parseFloat(evNotes[0].note))
                                lastI = i + 1
                            }

                        }

                        if (notes.length){

                            let sum = 0

                            notes.forEach(note => { sum += note })

                            doc.text('M1: ', data.cell.x + 1, data.cell.y + (5 * (lastI + 1)))
                            doc.text(String(sum / notes.length), data.cell.x + 7, data.cell.y + (5 * (lastI + 1)))

                        }

                    }

                }

            })

            doc.save( (this.currentClass ? (this.getClassName(this.currentClass.id) + (' - ' + this.getYearCuttingName(this.currentYearCutting)) ) : 'list') + '.pdf' )

        }
    }
}
</script>

<style scoped>
    ::v-deep(.table-header .el-card__body){
        padding: 10px;
        background: white;
    }

    ::v-deep(.el-card__body) {
        background: whitesmoke;
    }

    .table-header-overflow{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        vertical-align: middle;
        width: 80%;
    }
</style>
<template>

    <el-card class="mb-5" shadow="never">
        <el-row :gutter="10">
            <el-col :span="12" class="text-left">
                <el-button
                    size="small"
                    type="success"
                    icon="document"
                    :disabled="!currentYearCutting || !currentStudentId || !activeEvaluations.length" @click="print(true)" v-if="false">
                    {{ $t('downloadInPDF') }}
                </el-button>
            </el-col>
            <el-col :span="5" class="text-right">
                <el-select
                    size="small"
                    filterable
                    v-model="currentYearCutting"
                    :no-data-text="$t('noData')"
                    :no-match-text="$t('noMatchingData')"
                    :placeholder="isYearCuttingTerm ? $t('selectTrimester') : $t('selectSemester')"
                    @change="print()"
                    style="width: 100%">
                    <el-option
                        v-for="item in yearCuttings"
                        :key="item.id"
                        :label="getYearCuttingName(item.id)"
                        :value="item.id">
                    </el-option>
                </el-select>
            </el-col>
            <el-col :span="7" class="text-right">
                <el-select
                    clearable
                    filterable
                    size="small"
                    v-model="currentStudentId"
                    :no-data-text="$t('noData')"
                    :no-match-text="$t('noMatchingData')"
                    :placeholder="$t('selectStudent')"
                    @change="print()"
                    style="width: 100%">
                    <el-option
                        v-for="item in classStudents"
                        :key="item.id"
                        :value="item.id"
                        :label="getStudentName(item.id)">
                    </el-option>
                </el-select>
            </el-col>
        </el-row>
    </el-card>

    <div v-loading="isLoading || isCurrentDataLoading">

        <report-template
            :year-cutting="yearCuttings.find(y => y.id == currentYearCutting)"
            :student-class="classes.find(c => c.id == currentClass.id)"
            :student-activities="reportActivities"
            :class-size="tableData.length"
            :student="reportStudent"
            :verdict="reportVerdict"
            v-if="currentStudentId"
            class="mt-10"
            id="report"/>

        <el-card shadow="never" v-else>
            <div class="text-center">
                {{ $t('noStudentSelected') }}
            </div>
        </el-card>

    </div>

</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import accessManager from '@/mixins/access-manager'
import currentData from '@/mixins/current-data'

import { VarTypes } from '@/utils/enums'

import jsPDF from 'jspdf'
import 'jspdf-autotable'

import ReportTemplate from '@/views/admin/ReportManage/ReportTemplate'

import fr from 'element-plus/lib/locale/lang/fr'

export default {
    name: 'Reports',
    mixins: [accessManager, currentData],
    components: {
        ReportTemplate
    },
    props: {
        currentClass: {
            default: null
        }
    },
    created() {

        this.fetchData()

        this.currentYearCutting = this.defaultYearCutting

    },
    data() {
        return {
            VarTypes,
            primaryColor: process.env.VUE_APP_PRIMARY_COLOR,
            isLoading: false,
            reportVerdict: null,
            currentStudentId: null,
            currentYearCutting: null,
            classChoiceCourses: [],
            reportActivities: [],
            evaluationNotes: [],
            evaluationTexts: [],
            classCourses: [],
            evaluations: [],
            locale: fr
        }
    },
    computed: {
        ...mapState({
            userData: state => state.session.userData
        }),
        ...mapGetters({
            getSettingValue: 'settings/getSettingValue'
        }),
        tableData(){

            if (!this.currentSchoolyear || !this.currentClass) return []

            return this.$_.sortBy( this.students.filter(s => s.class == this.currentClass.id), 'lastname' )

        },
        activeEvaluations(){

            return this.$_.sortBy( this.evaluations.filter(e => parseInt(e.display) && e.yearcutting == this.currentYearCutting), 'type' ).reverse()

        },
        classStudents(){

            if (this.currentClass) return this.$_.sortBy( this.students.filter(s => s.class == this.currentClass.id), 'lastname' )
            else return []

        },
        reportStudent(){

            if (this.currentStudentId) return this.students.find(student => student.id == this.currentStudentId)
            else return null

        }
    },
    methods: {
        ...mapActions({
            fetchUserDB: 'user/fetchUserDB',
            fetchRepartitionDB: 'repartition/fetchRepartitionDB',
            fetchEvaluationsDB: 'evaluations/fetchEvaluationsDB',
            fetchChoiceCoursesDB: 'choicecourses/fetchChoiceCoursesDB',
            fetchEvaluationNotesDB: 'evaluationnotes/fetchEvaluationNotesDB',
            fetchEvaluationTextsDB: 'evaluationtexts/fetchEvaluationTextsDB',
            fetchChosenActivitiesDB: 'chosenactivities/fetchChosenActivitiesDB',
            fetchEvaluationVerdictDB: 'evaluationverdict/fetchEvaluationVerdictDB'
        }),
        async fetchData(){

            this.isLoading = true

            if (this.currentClass){

                let resp0 = await this.fetchChoiceCoursesDB({
                    action: 'getClassCourses',
                    params: {
                        class: this.currentClass.id,
                        schoolyear: this.currentSchoolyear
                    }
                })

                if (resp0.success && resp0.data) this.classChoiceCourses = resp0.data

                let resp1 = await this.fetchRepartitionDB({
                    action: 'getClassCourses',
                    params: {
                        class: this.currentClass.id,
                        schoolyear: this.currentSchoolyear
                    }
                })

                if (resp1.success && resp1.data) this.classCourses = resp1.data

                let resp2 = await this.fetchEvaluationsDB({
                    action: 'getClassEvaluations',
                    params: {
                        class: this.currentClass.id,
                        schoolyear: this.currentSchoolyear
                    }
                })

                if (resp2.success && resp2.data) this.evaluations = resp2.data

                let resp = await this.fetchEvaluationNotesDB({
                    action: 'getClassEvaluationNotes',
                    params: {
                        class: this.currentClass.id,
                        schoolyear: this.currentSchoolyear
                    }
                })

                if (resp.success && resp.data) this.evaluationNotes = resp.data

                let resp_ = await this.fetchEvaluationTextsDB({
                    action: 'getClassEvaluationTexts',
                    params: {
                        class: this.currentClass.id,
                        schoolyear: this.currentSchoolyear
                    }
                })

                if (resp_.success && resp_.data) this.evaluationTexts = resp_.data

            }

            this.isLoading = false

        },
        async print(toPDF = false){

            this.updateDefaultYearCutting(this.currentYearCutting)

            if (this.isCurrentDataLoading || this.isLoading) {

                this.$message.warning(this.$t('reportDataUnavailableMessage'))

                return

            }

            if (this.reportStudent){

                const report = document.getElementById('report')

                let resp = await this.fetchEvaluationVerdictDB({
                    action: 'getStudentEvaluationVerdict',
                    params: {
                        student: this.reportStudent.id,
                        class: this.reportStudent.class,
                        yearcutting: this.currentYearCutting || 0,
                        schoolyear: this.currentSchoolyear
                    }
                })

                if (resp.success && resp.data) this.reportVerdict = resp.data[0]
                else this.reportVerdict = null

                let resp_ = await this.fetchChosenActivitiesDB({
                    action: 'getStudentChosenActivities',
                    params: {
                        student: this.reportStudent.id,
                        schoolyear: this.currentSchoolyear
                    }
                })

                if (resp_.success && resp_.data) this.reportActivities = resp_.data
                else this.reportActivities = []

                try {

                    const doc = new jsPDF({
                        orientation: 'p',
                        format: 'a4',
                        unit: 'pt'
                    })

                    let dWidth = doc.internal.pageSize.width
                    let srcWidth = report.scrollWidth
                    let margin = 24

                    let scale = (dWidth - margin * 2) / srcWidth

                    var self = this

                    doc.html(report, {
                        callback: function (doc) {
                            if (toPDF) {
                                doc.autoPrint({variant: 'non-conform'})
                                doc.save( self.$t('report') + ' - ' + self.getStudentName(self.reportStudent.id) + '.pdf' )
                            }
                        },
                        x: margin,
                        y: margin,
                        html2canvas: {
                            scale: scale,
                            onclone: function (clonedDoc) {
                                clonedDoc.getElementById('report').style.visibility = 'visible'
                            }
                        }
                    })

                } catch (error) {

                }

            }

        }
    }
}
</script>

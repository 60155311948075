<template>

    <el-card class="mb-5" shadow="never">
        <el-row :gutter="10">
            <el-col :span="18" class="text-left">
                <el-button type="primary" size="small" icon="upload" :disabled="!currentYearCutting" @click="handleDownload()">
                    {{ $t('exportAppreciations') }}
                </el-button>
                <el-button type="warning" size="small" icon="download" :disabled="!currentYearCutting" @click="uploadDialog = true">
                    {{ $t('importAppreciations') }}
                </el-button>
                <el-button type="success" size="small" icon="document" :disabled="!currentYearCutting" @click="toPDF()">
                    {{ $t('downloadInPDF') }}
                </el-button>
            </el-col>
            <el-col :span="6" class="text-right">
                <el-select
                    size="small"
                    filterable
                    v-model="currentYearCutting"
                    :no-data-text="$t('noData')"
                    :no-match-text="$t('noMatchingData')"
                    :placeholder="isYearCuttingTerm ? $t('selectTrimester') : $t('selectSemester')"
                    @change="loadList()"
                    style="width: 100%">
                    <el-option
                        v-for="item in yearCuttings"
                        :key="item.id"
                        :label="getYearCuttingName(item.id)"
                        :value="item.id">
                    </el-option>
                </el-select>
            </el-col>
        </el-row>
    </el-card>

    <el-card shadow="hover" v-if="currentClass" class="list-container-card">

        <div v-loading="isLoading || isCurrentDataLoading">

            <el-card shadow="never" class="table-header">
                <el-row :gutter="10">
                    <el-col :span="14" class="text-left"></el-col>
                    <el-col :span="5" class="text-right">

                        <template v-if="editMode">
                            <el-row :gutter="10">
                                <el-col :span="12">
                                    <el-button @click="cancelItemsEdition()" size="small" icon="close">{{ $t('cancel') }}</el-button>
                                </el-col>
                                <el-col :span="12">
                                    <el-button type="primary" @click="submitItemsEdition()" icon="check" size="small">{{ $t('validate') }}</el-button>
                                </el-col>
                            </el-row>
                        </template>

                        <el-button
                            size="small"
                            @click="editItems()"
                            :disabled="!currentYearCutting"
                            icon="edit"
                            v-else>
                            {{ $t('editAppreciations') }}
                        </el-button>

                    </el-col>
                    <el-col :span="5" class="text-right">
                        <el-input v-model="search" :disabled="editMode" size="small" :placeholder="$t('search')" />
                    </el-col>
                </el-row>
            </el-card>

            <el-table
                stripe
                border
                max-height="500"
                :data="pagedTableData"
                :default-sort="{prop: 'lastname', order: 'ascending'}"
                :empty-text="$t('noData')" style="width: 100%">

                <el-table-column type="index" :index="indexMethod" width="50" align="center" fixed> </el-table-column>
                <el-table-column :label="$t('photo')" prop="photo" width="90" align="center" header-align="center" fixed>
                    <template #default="scope">
                        <el-avatar :src="scope.row.photo" v-if="scope.row.photo"></el-avatar>
                        <el-avatar icon="avatar" v-else></el-avatar>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('lastname')" prop="lastname" show-overflow-tooltip min-width="150" fixed> </el-table-column>
                <el-table-column :label="$t('firstnames')" prop="firstname" show-overflow-tooltip min-width="160" fixed> </el-table-column>

                <template v-if="currentYearCutting">

                    <el-table-column :label="$t('managementAppreciation')" prop="verdict" min-width="250">
                        <template #default="scope">

                            <el-row :gutter="10" v-if="editMode">
                                <el-col :span="24">
                                    <el-input
                                        type="textarea"
                                        style="width: 100%"
                                        v-model="editItemsData[scope.$index].verdict"
                                        :placeholder="$t('managementAppreciation')">
                                    </el-input>
                                </el-col>
                            </el-row>

                            <template v-else>

                                {{ scope.row.verdict }}

                            </template>

                        </template>
                    </el-table-column>

                    <el-table-column :label="$t('conduct')" prop="conduct" min-width="250">
                        <template #default="scope">

                            <el-row :gutter="10" v-if="editMode">
                                <el-col :span="24">
                                    <el-input
                                        type="textarea"
                                        style="width: 100%"
                                        v-model="editItemsData[scope.$index].conduct"
                                        :placeholder="$t('conduct')">
                                    </el-input>
                                </el-col>
                            </el-row>

                            <template v-else>

                                {{ scope.row.conduct }}

                            </template>

                        </template>
                    </el-table-column>

                    <el-table-column :label="$t('congratulations')" prop="congratulations" min-width="180">
                        <template #default="scope">

                            <el-row :gutter="10" v-if="editMode">
                                <el-col :span="24">
                                    <el-checkbox v-model="editItemsData[scope.$index].congratulations" :true-label="1" :false-label="0"/>
                                </el-col>
                            </el-row>

                            <template v-else>

                                <el-icon style="color: green; font-weight: bold" v-if="scope.row.congratulations && parseInt(scope.row.congratulations)">
                                    <Check/>
                                </el-icon>
                                <el-icon style="color: red; font-weight: bold" v-else>
                                    <Close/>
                                </el-icon>

                            </template>

                        </template>
                    </el-table-column>

                    <el-table-column :label="$t('compliments')" prop="compliments" min-width="180">
                        <template #default="scope">

                            <el-row :gutter="10" v-if="editMode">
                                <el-col :span="24">
                                    <el-checkbox v-model="editItemsData[scope.$index].compliments" :true-label="1" :false-label="0"/>
                                </el-col>
                            </el-row>

                            <template v-else>

                                <el-icon style="color: green; font-weight: bold" v-if="scope.row.compliments && parseInt(scope.row.compliments)">
                                    <Check/>
                                </el-icon>
                                <el-icon style="color: red; font-weight: bold" v-else>
                                    <Close/>
                                </el-icon>

                            </template>

                        </template>
                    </el-table-column>

                    <el-table-column :label="$t('encouragements')" prop="encouragements" min-width="180">
                        <template #default="scope">

                            <el-row :gutter="10" v-if="editMode">
                                <el-col :span="24">
                                    <el-checkbox v-model="editItemsData[scope.$index].encouragements" :true-label="1" :false-label="0"/>
                                </el-col>
                            </el-row>

                            <template v-else>

                                <el-icon style="color: green; font-weight: bold" v-if="scope.row.encouragements && parseInt(scope.row.encouragements)">
                                    <Check/>
                                </el-icon>
                                <el-icon style="color: red; font-weight: bold" v-else>
                                    <Close/>
                                </el-icon>

                            </template>

                        </template>
                    </el-table-column>

                    <el-table-column :label="$t('workAlert')" prop="work_alert" min-width="180">
                        <template #default="scope">

                            <el-row :gutter="10" v-if="editMode">
                                <el-col :span="24">
                                    <el-checkbox v-model="editItemsData[scope.$index].work_alert" :true-label="1" :false-label="0"/>
                                </el-col>
                            </el-row>

                            <template v-else>

                                <el-icon style="color: green; font-weight: bold" v-if="scope.row.work_alert && parseInt(scope.row.work_alert)">
                                    <Check/>
                                </el-icon>
                                <el-icon style="color: red; font-weight: bold" v-else>
                                    <Close/>
                                </el-icon>

                            </template>

                        </template>
                    </el-table-column>

                    <el-table-column :label="$t('conductAlert')" prop="conduct_alert" min-width="180">
                        <template #default="scope">

                            <el-row :gutter="10" v-if="editMode">
                                <el-col :span="24">
                                    <el-checkbox v-model="editItemsData[scope.$index].conduct_alert" :true-label="1" :false-label="0"/>
                                </el-col>
                            </el-row>

                            <template v-else>

                                <el-icon style="color: green; font-weight: bold" v-if="scope.row.conduct_alert && parseInt(scope.row.conduct_alert)">
                                    <Check/>
                                </el-icon>
                                <el-icon style="color: red; font-weight: bold" v-else>
                                    <Close/>
                                </el-icon>

                            </template>

                        </template>
                    </el-table-column>

                </template>

            </el-table>

            <div style="text-align: center; margin-top: 20px">

                <el-pagination
                    background
                    layout="prev, pager, next"
                    @current-change="setPage"
                    :hide-on-single-page="true"
                    :page-size="pageSize"
                    :total="total">
                </el-pagination>

            </div>

        </div>

    </el-card>

    <el-dialog
        width="40%"
        destroy-on-close
        v-model="uploadDialog"
        :title="$t('importNotes')">

        <upload-excel :on-success="handleImport"/>

    </el-dialog>

</template>

<script>
import { mapState, mapActions } from 'vuex'

import UploadExcel from '@/components/widgets/UploadExcel'

import accessManager from '@/mixins/access-manager'
import currentData from '@/mixins/current-data'

import { VarTypes } from '@/utils/enums'

import jsPDF from 'jspdf'
import 'jspdf-autotable'

import fr from 'element-plus/lib/locale/lang/fr'

export default {
    name: 'ClassList',
    mixins: [accessManager, currentData],
    components: {
        UploadExcel
    },
    props: {
        currentClass: {
            default: 0
        }
    },
    created() {

        this.fetchData()

        this.currentYearCutting = this.defaultYearCutting

    },
    data() {
        return {
            VarTypes,
            primaryColor: process.env.VUE_APP_PRIMARY_COLOR,
            editItemsData: null,
            editMode: false,
            isLoading: false,
            currentYearCutting: null,
            uploadDialog: false,
            appreciations: [],
            classCourses: [],
            tableData: [],
            pageSize: 50,
            locale: fr,
            search: '',
            total: 0,
            page: 1
        }
    },
    watch: {
        isCurrentDataLoading: {
            handler(val, oldVal) { this.loadList() }
        }
    },
    computed: {
        ...mapState({
            userData: state => state.session.userData
        }),
        pagedTableData() {

            this.total = this.searching.length

            return this.searching.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)

        },
        searching() {

            if (!this.search) {
                this.total = this.tableData.length
                return this.tableData
            }

            this.page = 1

            return this.tableData.filter(
                data => (data.lastname.toLowerCase().includes(this.search.toLowerCase())
                        || data.firstname.toLowerCase().includes(this.search.toLowerCase()))
            )

        },
        classStudents(){

            if (this.currentClass) return this.$_.sortBy( this.students.filter(s => s.class == this.currentClass.id), 'lastname' )
            else return []

        },
    },
    methods: {
        ...mapActions({
            fetchRepartitionDB: 'repartition/fetchRepartitionDB',
            fetchEvaluationVerdictDB: 'evaluationverdict/fetchEvaluationVerdictDB',
        }),
        setPage (val) {

            this.page = val

        },
        indexMethod(index) {

            return index + ((this.page - 1) * this.pageSize) + 1

        },
        async fetchData(){

            this.isLoading = true

            let resp = await this.fetchRepartitionDB({
                action: 'getClassCourses',
                params: {
                    class: this.currentClass.id,
                    schoolyear: this.currentSchoolyear
                }
            })

            if (resp.success && resp.data) this.classCourses = resp.data

            this.isLoading = false

        },
        async loadList(){

            this.editMode = false

            this.updateDefaultYearCutting(this.currentYearCutting)

            if (this.$route.params.class){

                let resp = await this.fetchEvaluationVerdictDB({
                    action: 'getClassEvaluationVerdict',
                    params: {
                        class: this.$route.params.class,
                        schoolyear: this.currentSchoolyear
                    }
                })

                if (resp.success && resp.data) this.appreciations = resp.data

            }

            this.tableData = []

            this.classStudents.forEach(student => {

                let data = {
                    id: student.id,
                    lastname: student.lastname,
                    firstname: student.firstname
                }

                if (this.currentYearCutting){

                    this.appreciations.forEach(ap => {

                        if (ap.yearcutting == this.currentYearCutting && ap.student == student.id){

                            data['verdict'] = ap.verdict
                            data['conduct'] = ap.conduct
                            data['congratulations'] = ap.congratulations && parseInt(ap.congratulations)
                            data['compliments'] = ap.compliments && parseInt(ap.compliments)
                            data['encouragements'] = ap.encouragements && parseInt(ap.encouragements)
                            data['work_alert'] = ap.work_alert && parseInt(ap.work_alert)
                            data['conduct_alert'] = ap.conduct_alert && parseInt(ap.conduct_alert)

                        }

                    })

                }

                this.tableData.push(data)

            })

        },
        editItems(){

            this.editItemsData = this.$_.cloneDeep(this.tableData)

            this.search = ''

            this.editMode = true

        },
        cancelItemsEdition(){

            this.editItemsData = null

            this.editMode = false

        },
        async submitItemsEdition(){

            for (let y = 0; y < this.editItemsData.length; y++) {

                if (!this.$_.isEqual(this.editItemsData[y], this.tableData[y])) {

                    await this.fetchEvaluationVerdictDB({
                        action: 'updateEvaluationVerdict',
                        params: {
                            class: this.$route.params.class,
                            yearcutting: this.currentYearCutting,
                            schoolyear: this.currentSchoolyear,
                            student: this.editItemsData[y].id,
                            verdict: this.editItemsData[y].verdict || '',
                            conduct: this.editItemsData[y].conduct || '',
                            congratulations: this.editItemsData[y].congratulations ? 1 : 0,
                            compliments: this.editItemsData[y].compliments ? 1 : 0,
                            encouragements: this.editItemsData[y].encouragements ? 1 : 0,
                            work_alert: this.editItemsData[y].work_alert ? 1 : 0,
                            conduct_alert: this.editItemsData[y].conduct_alert ? 1 : 0
                        }
                    })

                }

            }

            this.loadList()

            this.editMode = false

        },
        handleDownload() {

            let mes = this.$message({ message: this.$t('pleaseWait...'), duration: 0 })

            import('@/utils/export2excel').then(excel => {

                var tHeader = ['ID', this.$t('lastname'), this.$t('firstnames'), this.$t('managementAppreciation'), this.$t('conduct'), this.$t('congratulations'), this.$t('compliments'), this.$t('encouragements'), this.$t('workAlert'), this.$t('conductAlert')],
                    filterVal = ['id', 'lastname', 'firstname', 'verdict', 'conduct', 'congratulations', 'compliments', 'encouragements', 'work_alert', 'conduct_alert']

                var list = this.$_.sortBy( this.tableData, 'lastname' )

                for (let i = 0; i < list.length; i++) {

                    if (list[i].congratulations == 0) list[i].congratulations = this.$t('no')
                    else list[i].congratulations = this.$t('yes')

                    if (list[i].compliments == 0) list[i].compliments = this.$t('no')
                    else list[i].compliments = this.$t('yes')

                    if (list[i].encouragements == 0) list[i].encouragements = this.$t('no')
                    else list[i].encouragements = this.$t('yes')

                    if (list[i].work_alert == 0) list[i].work_alert = this.$t('no')
                    else list[i].work_alert = this.$t('yes')

                    if (list[i].conduct_alert == 0) list[i].conduct_alert = this.$t('no')
                    else list[i].conduct_alert = this.$t('yes')

                }

                const data = this.formatJson(filterVal, list)

                try {

                    excel.export_json_to_excel({
                        header: tHeader,
                        data,
                        filename: this.currentClass ? (this.getClassName(this.currentClass.id) + (' - ' + this.getYearCuttingName(this.currentYearCutting)) + ' - ' + this.$t('appreciations')) : '',
                        autoWidth: true,
                        bookType: 'xlsx'
                    })

                    mes.close()

                } catch (error) {

                    mes.close()

                    this.$message.error(this.$t('anErrorOccured'))

                }

            })

        },
        async handleImport({ results, header }) {

            for (let y = 0; y < results.length; y++) {

                let student = this.tableData.find(d => d.id == results[y].ID)

                if (student) {

                    await this.fetchEvaluationVerdictDB({
                        action: 'updateEvaluationVerdict',
                        params: {
                            class: this.$route.params.class,
                            yearcutting: this.currentYearCutting,
                            schoolyear: this.currentSchoolyear,
                            student: student.id,
                            verdict: results[y][this.$t('managementAppreciation')] || '',
                            conduct: results[y][this.$t('conduct')] || '',
                            congratulations: results[y][this.$t('congratulations')].toLowerCase() == this.$t('yes').toLowerCase() ? 1 : 0,
                            compliments: results[y][this.$t('compliments')].toLowerCase() == this.$t('yes').toLowerCase() ? 1 : 0,
                            encouragements: results[y][this.$t('encouragements')].toLowerCase() == this.$t('yes').toLowerCase() ? 1 : 0,
                            work_alert: results[y][this.$t('workAlert')].toLowerCase() == this.$t('yes').toLowerCase() ? 1 : 0,
                            conduct_alert: results[y][this.$t('conductAlert')].toLowerCase() == this.$t('yes').toLowerCase() ? 1 : 0
                        }
                    })

                }

            }

            this.loadList()

            this.uploadDialog = false

        },
        toPDF() {

            var tHeader = ['N°', this.$t('lastname'), this.$t('firstnames'), this.$t('managementAppreciation'), this.$t('conduct'), this.$t('congratulations'), this.$t('compliments'), this.$t('encouragements'), this.$t('workAlert'), this.$t('conductAlert')],
                filterVal = ['num', 'lastname', 'firstname', 'verdict', 'conduct', 'congratulations', 'compliments', 'encouragements', 'work_alert', 'conduct_alert'],
                finalHeader = []

            var columnStyles = {
                num: { halign: 'center' },
                lastname: { halign: 'left' },
                firstname: { halign: 'left' },
                verdict: { halign: 'left' },
                conduct: { halign: 'left' },
                congratulations: { halign: 'left' },
                compliments: { halign: 'left' },
                encouragements: { halign: 'left' },
                work_alert: { halign: 'left' },
                conduct_alert: { halign: 'left' },
            }

            for (let i = 0; i < filterVal.length; i++) {

                finalHeader.push({
                    header: tHeader[i],
                    dataKey: filterVal[i]
                })

            }

            var tempList = this.$_.sortBy( this.tableData, 'lastname' )
            for (let i = 0; i < tempList.length; i++) {

                tempList[i].num = i + 1

                if (tempList[i].congratulations == 0) tempList[i].congratulations = this.$t('no')
                else tempList[i].congratulations = this.$t('yes')

                if (tempList[i].compliments == 0) tempList[i].compliments = this.$t('no')
                else tempList[i].compliments = this.$t('yes')

                if (tempList[i].encouragements == 0) tempList[i].encouragements = this.$t('no')
                else tempList[i].encouragements = this.$t('yes')

                if (tempList[i].work_alert == 0) tempList[i].work_alert = this.$t('no')
                else tempList[i].work_alert = this.$t('yes')

                if (tempList[i].conduct_alert == 0) tempList[i].conduct_alert = this.$t('no')
                else tempList[i].conduct_alert = this.$t('yes')

            }

            const data = this.formatJson(filterVal, tempList)

            const doc = new jsPDF('landscape')

            var finalY = doc.lastAutoTable.finalY || 10

            doc.text(this.currentClass ? this.getClassName(this.currentClass.id) : '', 14, finalY + 15)
            doc.setFontSize(11)
            doc.text(this.getYearCuttingName(this.currentYearCutting), 14, finalY + 25)

            doc.autoTable({
                body: data,
                theme: 'grid',
                columns: finalHeader,
                columnStyles: columnStyles,
                bodyStyles: { cellPadding: {top: 4, right: 2, bottom: 4, left: 2} },
                headStyles: { fillColor: [33, 150, 243] },
                startY: finalY + 30
            })

            doc.save( (this.currentClass ? (this.getClassName(this.currentClass.id) + (' - ' + this.getYearCuttingName(this.currentYearCutting)) + ' - ' + this.$t('appreciations') ) : 'list') + '.pdf' )

        }
    }
}
</script>

<style scoped>
    ::v-deep(.table-header .el-card__body){
        padding: 10px;
        background: white;
    }

    ::v-deep(.el-card__body) {
        background: whitesmoke;
    }

    .table-header-overflow{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        vertical-align: middle;
        width: 80%;
    }
</style>
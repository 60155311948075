<template>

    <el-card shadow="hover" class="mb-10 header">
        <el-row>
            <el-col :span="12">

                <el-page-header
                    :title="$t('back')"
                    :content="currentClass ? getClassName(currentClass.id) : ''"
                    @back="$router.back()"/>

            </el-col>
            <el-col :span="12" class="text-right">

                <el-radio-group v-model="displayType" class="page-display-type" v-if="!isLoading && hasIncumbentAccess" size="small">
                    <el-radio-button :label="0">{{ $t('displayByCourses') }}</el-radio-button>
                    <el-radio-button :label="1">{{ $t('displayByStudents') }}</el-radio-button>
                    <el-radio-button :label="2">{{ $t('appreciations') }}</el-radio-button>
                    <el-radio-button :label="3">{{ $t('reports') }}</el-radio-button>
                </el-radio-group>

            </el-col>
        </el-row>
    </el-card>

    <template v-if="!isLoading">

        <template v-if="hasIncumbentAccess">

            <display-by-courses :current-class="currentClass" v-if="displayType === 0"></display-by-courses>
            <display-by-students :current-class="currentClass" v-if="displayType === 1"></display-by-students>
            <appreciations :current-class="currentClass" v-if="displayType === 2"></appreciations>
            <reports :current-class="currentClass" v-if="displayType === 3"></reports>

        </template>

        <el-alert
            :title="$t('dontHaveAccessClassData')"
            type="warning"
            class="mb-5"
            show-icon
            v-else/>

    </template>

</template>

<script>
import { mapState } from 'vuex'

import accessManager from '@/mixins/access-manager'
import currentData from '@/mixins/current-data'

import Reports from './Reports'
import Appreciations from './Appreciations'
import DisplayByCourses from './DisplayByCourses'
import DisplayByStudents from './DisplayByStudents'

export default {
    name: 'ClassList',
    mixins: [accessManager, currentData],
    components: {
        Reports,
        Appreciations,
        DisplayByCourses,
        DisplayByStudents
    },
    created() {

        this.fetchData()

    },
    data() {
        return {
            displayType: 0,
            isLoading: false,
            currentClass: null,
        }
    },
    computed: {
        ...mapState({
            userData: state => state.session.userData
        }),
        hasIncumbentAccess(){

            return this.currentClass && this.currentClass.incumbent == this.userData.roleData.id

        },
    },
    methods: {
        async fetchData(){

            this.isLoading = true

            let resp = await this.fetchClassesDB({
                action: 'getClass',
                params: {
                    id: this.$route.params.class,
                }
            })

            if (resp.success && resp.data) this.currentClass = resp.data

            this.isLoading = false

        }
    }
}
</script>

<style scoped>
    ::v-deep(.el-card__body) {
        background: whitesmoke;
    }

    ::v-deep(.el-page-header){
        align-items: baseline;
        padding-top: 2px;
    }
</style>